import { lazy } from 'react';

//web routes here

const WHome = lazy(() => import('../web/pages/home'));
const WProductlist = lazy(() => import('../web/pages/product/product_list'));
const CMSWeb = lazy(() => import('../web/pages/CMS'));
const ContactUs = lazy(() => import('../web/pages/ContactUs'));
const WProductdetai = lazy(() => import('../web/pages/product/product_detail'));
const WCart = lazy(() => import('../web/pages/cart/cart'));
const WAddress = lazy(() => import('../web/pages/profile/AllAddress'));
const WPaymentMethod = lazy(() => import('../web/pages/payment/PaymentMethod'));
const WCartclear = lazy(() => import('../web/pages/cart/WEmptyCart'));
const AccountDashboard = lazy(() => import('../web/pages/tabbing/tab'));
const WNotifications = lazy(() => import('../web/pages/notifications'));
const WAboutClefill = lazy(() => import('../web/pages/aboutClefill'));
const Success_msg = lazy(() => import('../web/pages/success _msg'));

export const webRoutes = [

    { path: "/", exact: true, name: "Home", element: WHome },
    { path: "/product_list/:catId/:typeId", exact: true, name: "Product_list", element: WProductlist },
    { path: "/cms/:key", exact: true, name: "CMS", element: CMSWeb },
    // { path: "/product_list", exact: true, name: "Product_list", element: WProductlist },
    { path: "/product_detail/:catId/:id", exact: true, name: "Product_detail", element: WProductdetai },
    { path: "/cart", exact: true, name: "cart", element: WCart },
    { path: "/shipping_address", exact: true, name: "Address", element: WAddress },
    { path: "/payment_method", exact: true, name: "Payment_method", element: WPaymentMethod },
    { path: "/success/:id", exact: true, name: "Success", element: Success_msg },
    { path: "/cart_clear", exact: true, name: "Payment_method", element: WCartclear },
    { path: "/account_dashboard/:index", exact: true, name: "AccountDashboard", element: AccountDashboard },
    { path: "/notifications", exact: true, name: "AccountDashboard", element: WNotifications },
    { path: "/contactus", exact: true, name: "Contact Us", element: ContactUs },
    { path: "/about_clefill", exact: true, name: "FAQ", element: WAboutClefill },
    // { path: "*", exact: false, name: "Home", element: WHome },
]